<template>
    <div class="page-container">
        <h1>Hello Welcome {{user ? user.username : ''}} {{genderText}}</h1>
        <router-link to="/test" class="app-float-btn round-999">go page test</router-link>
        <button @click="doLogout">注销</button>
        <checkbox v-model="singleCheck" value="勾选协议" label="我来啦"></checkbox>

        <checkbox v-model="arr" value="大乔" type="custom" color="#f00"></checkbox>
        <checkbox v-model="arr" value="小乔" color="#990" disabled></checkbox>
        <checkbox v-model="arr" value="凤姐" disabled></checkbox>

        <checkbox v-model="arr2" value="xs" label="西施" color="#990" :custom-style="checkBoxCustomStyle"></checkbox>
        <checkbox v-model="arr2" value="dc" label="貂蝉" color="#990"></checkbox>

        <radio v-model="isAdult"  value="0" label="否" color="#990"></radio>
        <radio v-model="isAdult"  value="1" label="是" type="success"></radio>
        <radio v-model="isAdult"  value="-1" label="未知" disabled></radio>
        <radio v-model="isAdult"  value="-2" label="未知2" disabled></radio>
        <radio v-model="isAdult"  value="-3" label="未知3" disabled color="#990"></radio>
        <radio v-model="isAdult"  value="-4" label="未知4" disabled :custom-style="checkBoxCustomStyle"></radio>
        <radio v-model="isAdult2"  value="-5" label="未知5" color='red' :custom-style="checkBoxCustomStyle"></radio>
        <radio v-model="isAdult2"  value="-6" label="未知6" :custom-style="checkBoxCustomStyle2"></radio>
        <br>
        <div>
            <span class="app-float-btn app-round-999" style="color:#fff; background-color:blue; padding: 3px 5px;">注销4</span>
            <span class="app-float-btn" style="color:#fff; background-color:#F44336; padding: 3px 5px;">注销4</span>
        </div>
    </div>
</template>

<script>
    import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
    import Checkbox from "@/components/Checkbox";
    import Radio from "@/components/Radio";
    import axios from 'axios';

    export default {
        name: "test-nested",
        components: {
            Checkbox,
            Radio,
        },
        data() {
            return {
                singleCheck: true,
                arr: ['凤姐'],
                arr2: ['xs', 'dc'],
                arr2Label: ['西施', '貂蝉'],
                checkBoxCustomStyle: {
                    colorDefault: '#ce9898',
                    fs: '40px',
                    size: '64px'
                },
                checkBoxCustomStyle2: {
                    colorDefault: '#ce9898',
                    fs: '16px',
                    size: '20px'
                },
                isAdult: -4,
                isAdult2: -5,
                gender: 2,
            }
        },
        created () {
          this.getMenu();
        },
        computed: {
            ...mapState(['user']),
            ...mapGetters(['genderText'])
        },
        methods: {
            ...mapMutations(['logout']),
            ...mapActions(['getMenu']),
            login() {
                this.$request({
                    url: '/auth/login'
                });
            },
            refreshToken() {
                this.$request.post('auth/refresh-token');
            },
            doLogout() {
                let yes = confirm('确定退出?');
                if(yes) {
                    this.logout();
                    this.$router.push('/login');
                }
            }
        }
    }
</script>

<style scoped>

</style>